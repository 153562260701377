import { CloMethod, CloParams } from 'models/common/CloParams.types';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

const cloClient = async (method: CloMethod, params: CloParams): Promise<string | null> => {
  try {
    let cloType = lastName(method);

    if (cloType !== null) {
      const functions = getFunctions(getApp(), process.env.NEXT_PUBLIC_FIREBASE_FUNCTIONS_REGION);

      const cloParams: any = {
        code: params.code,
        title: params.title
      };
      if (params.msg) cloParams.msg = params.msg;
      if (params.which) cloParams.which = params.which;
      if (params.param) cloParams.param = params.param;

      const result = await httpsCallable(functions, `call-cdm-clo-${cloType}`)(cloParams).catch(console.error);
      return (result?.data as string) ?? null;
    }

    console.warn(`Invalid method - ${method}`);
    return null;
  } catch (e) {
    console.error('[cloClient] clo 생성 중 오류', e);
    return null;
  }
};

export default cloClient;

function lastName(method: CloMethod): string | null {
  let cloType: string | null = null;
  switch (method) {
    case 'DEBUG':
      cloType = 'debug';
      break;
    case 'WARN':
      cloType = 'warn';
      break;
    case 'INFO':
      cloType = 'info';
      break;
    case 'ERROR':
      cloType = 'error';
      break;
  }
  return cloType;
}
