import { createSlice } from '@reduxjs/toolkit';
import { TempOrder } from 'models/TempOrder.types';

const initialState: TempOrder = {
  vendor: null,
  items: []
};

const tempOrderSlice = createSlice({
  name: 'tempOrder',
  initialState,
  reducers: {
    /** 주문 임시저장 */
    save(state: TempOrder, { payload }) {
      console.log('[reducer][temp-order] 주문 임시저장', payload);
      if (payload.vendor._id && payload.items.length > 0) {
        state.vendor = payload.vendor;
        state.items = payload.items;
        state.from = payload.from ?? 'web';
        if (payload.coupons) {
          state.coupons = payload.coupons;
        } else {
          delete state.coupons;
        }
      } else {
        state.vendor = null;
        state.items = [];
        delete state.coupons;
        delete state.from;
        delete state.suggest;
      }
    },
    /** 임시저장된 주문 업데이트 */
    update(state: TempOrder, { payload }) {
      if (payload.vendor) state.vendor = payload.vendor;
      if (typeof payload.items !== 'undefined') {
        state.items = payload.items;
      }
    },
    /** 임시저장된 주문 초기화 */
    reset(state) {
      state.vendor = null;
      state.items = [];
      delete state.coupons;
      delete state.from;
      delete state.suggest;
    }
  }
});
export const { save, update, reset } = tempOrderSlice.actions;
export default tempOrderSlice;
