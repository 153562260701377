import { useRouter } from 'next/router';

import React, { createContext, FC, ReactNode, useContext, useMemo } from 'react';

import { getApp } from 'firebase/app';
import { doc, getDoc, getFirestore } from '@firebase/firestore';
import * as FirebaseAuth from '@firebase/auth';

import * as amplitude from '@amplitude/analytics-browser';

import nookies from 'nookies';
import { removeCookie, setCookie } from 'react-use-cookie';
import axios from 'axios';

import Member from 'models/Member.model';

import { AuthState, initialState, setFirebaseAuth, setFirebaseAuthSignOut, setMember } from 'store/slices/wineone-auth';

import {
  closeSignInModal as closeSignInModalAction,
  ModalsState,
  openSignInModal as openSignInModalAction
} from 'store/slices/global-modals';

import { useAppDispatch, useAppSelector } from 'store';
import { GoogleAnalytics } from 'utils/analytics/google-analytics';
import { KakaoPixel } from 'utils/analytics/kakao-pixel';
import { SESSION_TOKEN } from 'config';

import SignIn from 'pages-sections/sessions/Sign-in';

import { Dialog, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

interface ContextProps {
  state: AuthState;
  /** 이메일 패스워드 로그인 */
  signInWithEmailAndPassword: (email: string, password: string) => Promise<{ success: boolean; error?: any }>;
  /** 애플 사용자 로그인 */
  signInWithApple: () => Promise<{ success: boolean; error?: any }>;
  /** 커스텀 토큰 로그인 (카카오) */
  signInWithCustomToken: (customToken: string) => Promise<{ success: boolean; error?: any }>;
  /** Firebase auth 로그아웃 */
  signOut: () => Promise<void>;

  /** 사용자 정보 갱신 */
  refreshUser: () => Promise<boolean>;

  /** 전역 로그인 모달 열기 */
  openSignInModal: () => void;
  /** 전역 로그인 모달 닫기 */
  closeSignInModal: () => void;
}

const AuthContext = createContext<ContextProps>({
  state: initialState,
  signInWithEmailAndPassword: async () => ({ success: false }),
  signInWithApple: async () => ({ success: false }),
  signInWithCustomToken: async (customToken: string) => ({ success: false }),
  signOut: async () => {},
  /** 사용자 정보 갱신 */
  refreshUser: async () => false,
  /** 전역 로그인 모달 열기 */
  openSignInModal: () => void 0,
  /** 전역 로그인 모달 닫기 */
  closeSignInModal: () => void 0
});

// =======================================================
type AuthProviderProps = { children: ReactNode };
type SignedInUser = Pick<FirebaseAuth.User, 'uid' | 'displayName' | 'email' | 'emailVerified' | 'photoURL'> & {
  god: boolean;
};
// =======================================================

export const AuthProvider: FC<AuthProviderProps> = ({ children, ...rest }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const router = useRouter();

  const { state, modals }: { state: AuthState; modals: ModalsState } = useAppSelector((state) => {
    return {
      state: state['auth'],
      modals: state['modals']
    };
  });

  // URL이 변경될 때 마다 로그인 모달을 닫음
  router.events.on('routeChangeStart', () => {
    dispatch(closeSignInModalAction());
  });

  const contextValue: { state: AuthState } = useMemo(() => ({ state }), [state]);

  React.useEffect(() => {
    const auth = FirebaseAuth.getAuth();
    const unsubscribe = auth.onAuthStateChanged((firebaseUser: FirebaseAuth.User) => {
      // 코드 개선
      if (firebaseUser) {
        // console.log('----------------> firebaseUser: ', firebaseUser);
        // 필요한 정보만
        firebaseUser.getIdTokenResult(true).then(async (idTokenResult) => {
          // Firebase authentication에 user record만 있고 회원정보는 없는 경우
          if (typeof idTokenResult.claims['role'] === 'undefined') {
            console.log('Firebase auth만 있고 사용자 정보는 없는 user record.', idTokenResult);
            dispatch(setFirebaseAuthSignOut());
            return;
          }

          const userRole = idTokenResult.claims['role'];

          if (userRole === 'user') {
            const user: SignedInUser = {
              uid: firebaseUser.uid,
              displayName: firebaseUser.displayName,
              email: firebaseUser.email,
              emailVerified: firebaseUser.emailVerified,
              photoURL: firebaseUser.photoURL,
              god: idTokenResult.claims?.god === true
            };

            amplitude.setUserId(firebaseUser.uid);
            GoogleAnalytics.setUserId(firebaseUser.uid);
            try {
              window.clarity('set', 'userId', firebaseUser.uid);
            } catch (ce) {}

            console.debug(`[amplitude] setUserid('${firebaseUser.uid}')`);
            dispatch(setFirebaseAuth({ user }));
            dispatch(closeSignInModalAction());

            // firestore에서 회원정보 가져오기
            const member: Member = await getDoc(doc(getFirestore(getApp()), 'member', user.uid)).then((docSnap) => {
              const { _id: uid, role, state, uname, nickname, my_taste, profile_img }: any = docSnap.data();
              return { uid, role, state, uname, nickname, my_taste, profile_img };
            });

            // 앱 사용이 불가능한 회원 (정지된 회원)
            if (member.state !== 'OK') {
              console.warn(`올바르지 않은 상태의 회원입니다.[state=${member.state}]`);
              if (typeof window !== 'undefined')
                window.alert('서비스 이용가능한 상태가 아닌 계정정보\r\n[' + userRole + ']');
              dispatch(setFirebaseAuthSignOut());
              await signOut();
              return;
            }

            dispatch(setMember(member));

            // member와 userRecord의 닉네임 불일치
            let isDiff: boolean = member.nickname !== user.displayName;

            // member와 userRecord의 프로필이미지 불일치
            if (member?.profile_img?.thumb?.url) {
              isDiff = isDiff || member.profile_img.thumb.url !== user.photoURL;
            }
            if (isDiff) {
              console.debug('[AuthContext] UserRecord 갱신이 필요한 계정.');
              if (typeof window) {
                const updated = await axios.post('/api/auth/sync-record', { uid: user.uid }).then((response) => {
                  return response?.data?.updated ?? false;
                });
                if (updated) {
                  await firebaseUser.reload();
                  const auth = FirebaseAuth.getAuth();

                  if (auth.currentUser) {
                    const newUser: SignedInUser = {
                      uid: firebaseUser.uid,
                      displayName: firebaseUser.displayName,
                      email: firebaseUser.email,
                      emailVerified: firebaseUser.emailVerified,
                      photoURL: firebaseUser.photoURL,
                      god: user.god
                    };

                    dispatch(setFirebaseAuth({ user: newUser }));
                  }
                }
              }
            }
          } else {
            console.warn('[onAuthStateChanged] 회원 권한의 계정만 로그인 가능합니다.');
            if (typeof window !== 'undefined')
              window.alert('회원 권한의 사용자만 로그인이 가능합니다.\r\n[' + userRole + ']');
            dispatch(setFirebaseAuthSignOut());

            await signOut();
          }
        });
      } else {
        console.debug('[AuthContext] 비로그인 사용자');
        dispatch(setFirebaseAuthSignOut());
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      (window as any).nookies = nookies;
    } else {
      return;
    }

    const unsub = FirebaseAuth.getAuth().onIdTokenChanged(async (user) => {
      if (user === null) {
        console.debug('[AuthContext] 세션토큰 제거#1', user);
        removeCookie(SESSION_TOKEN);
        return;
      }

      const { accessToken } = user as FirebaseAuth.User & { accessToken?: string };

      try {
        const { claims, token } = await user.getIdTokenResult(true);

        // role이 'user'가 아닐경우
        if (claims?.role !== 'user') {
          console.debug('[AuthContext] 세션토큰 제거#2');
          removeCookie(SESSION_TOKEN);
        } else if (accessToken !== token) {
          console.debug('[AuthContext] 세션토큰 갱신');
          setCookie(SESSION_TOKEN, token, { days: 365, Secure: true });
        }
      } catch (e) {
        console.warn('[AuthContext] getIdTokenResult 오류', e);
        await signOut();
        // window.alert('사용자 정보갱신 중 오류가 발생했습니다.\r\n다시 로그인해주세요.');
      }
    });
    return () => {
      console.debug('[AuthContext] onIdTokenChanged unsubscribe');
      unsub();
    };
  }, []);

  // force refresh the token every 10 minutes
  React.useEffect(() => {
    const handle = setInterval(async () => {
      console.debug('[AuthContext] firebase authentication 자동갱신 시작...');
      try {
        const user = FirebaseAuth.getAuth().currentUser;
        if (user) await user.getIdToken(true);
      } catch (e) {
        console.warn('[AuthContext] firebase authentication 토큰갱신 실패', e);
      }
    }, 10 * 60 * 1000);
    return () => clearInterval(handle);
  }, []);

  /**
   * 이메일 패스워드로 로그인 하기
   */
  const signInWithEmailAndPassword = React.useCallback(
    async (email: string, password: string): Promise<{ success: boolean; error?: any }> => {
      const auth = FirebaseAuth.getAuth();

      await FirebaseAuth.setPersistence(auth, FirebaseAuth.browserLocalPersistence);

      try {
        const userCredential: FirebaseAuth.UserCredential | any = await FirebaseAuth.signInWithEmailAndPassword(
          auth,
          email,
          password
        ).catch((error) => {
          console.warn('[FirebaseAuth.signInWithEmailAndPassword] error', error);
          return { error };
        });

        // 이메일, 비밀번호 로그인 실패
        if (userCredential.error) {
          return { success: false, error: userCredential.error };
        }

        console.debug('[AuthContext] 이메일 로그인 성공');
        const { token } = await userCredential.user.getIdTokenResult(true);

        // nookies.set(null, SESSION_TOKEN, token, { path: '/' });
        setCookie(SESSION_TOKEN, token, { days: 365, Secure: true });

        amplitude.track('Login', {
          'auth provider': 'app',
          success: true,
          uid: userCredential.user.uid
        });
        GoogleAnalytics.logEvent('login', { method: 'email' });
        GoogleAnalytics.setUserId(userCredential.user.uid);
        KakaoPixel.login();

        return { success: true };
      } catch (error) {
        amplitude.track('Login', {
          'auth provider': 'app',
          success: false,
          err: error.message ?? 'Unexpected'
        });
        console.error('[AuthContext][signInWithEmailAndPassword] 로그인 처리 중 오류', error);
        return { success: false, error };
      }
    },
    []
  );

  /** 애플 로그인 */
  const signInWithApple = React.useCallback(async () => {
    const provider = new FirebaseAuth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.setCustomParameters({
      locale: 'ko_KR'
    });

    try {
      const userCredential = await FirebaseAuth.signInWithPopup(FirebaseAuth.getAuth(), provider);
      const { token } = await userCredential.user.getIdTokenResult(true);

      const member: Member | undefined = await getDoc(
        doc(getFirestore(getApp()), 'member', userCredential.user.uid)
      ).then((docSnap) => {
        const docData = docSnap.data();
        if (docData === undefined) return undefined;

        const { _id: uid, role, state, uname, nickname, my_taste, profile_img }: any = docData;
        return { uid, role, state, uname, nickname, my_taste, profile_img };
      });

      // 회원가입하지 않은 사용자
      if (typeof member === 'undefined') {
        console.warn('[AuthContext] 회원가입하지 않은 Apple 사용자');
        removeCookie(SESSION_TOKEN);
        return {
          success: false,
          provider: 'apple',
          error: new Error('회원가입하지 않은 Apple 사용자 입니다.', { cause: 'wineone/not-member' })
        };
      }
      // 애플 로그인 성공
      else {
        console.log('[AuthContext] 애플 로그인 성공', member);
        // nookies.set(null, SESSION_TOKEN, token, { path: '/' });
        setCookie(SESSION_TOKEN, token, { days: 365, Secure: true });

        amplitude.track('Login', {
          'auth provider': 'apple',
          success: true,
          uid: userCredential.user.uid
        });
        GoogleAnalytics.logEvent('login', { method: 'apple' });
        GoogleAnalytics.setUserId(userCredential.user.uid);
        KakaoPixel.login();

        return { success: true, provider: 'apple' };
      }
    } catch (error) {
      amplitude.track('Login', {
        'auth provider': 'apple',
        success: false,
        err: error.message ?? 'Unexpected'
      });
      console.error('[AuthContext] 애플 로그인 실패', error);
      return { success: false, provider: 'apple', error };
    }
  }, []);

  /** 커스텀 토큰 로그인 */
  const signInWithCustomToken = React.useCallback(async (customToken: string) => {
    console.debug('[AuthContext] 커스텀 토큰 로그인');

    try {
      const userCredential = await FirebaseAuth.signInWithCustomToken(FirebaseAuth.getAuth(), customToken);
      const { token } = await userCredential.user.getIdTokenResult(true);

      // nookies.set(null, SESSION_TOKEN, token, { path: '/' });
      setCookie(SESSION_TOKEN, token, { days: 365, Secure: true });

      amplitude.track('Login', {
        'auth provider': 'kakao',
        success: true,
        uid: userCredential.user.uid
      });
      GoogleAnalytics.logEvent('login', { method: 'kakao' });
      GoogleAnalytics.setUserId(userCredential.user.uid);
      KakaoPixel.login();

      return { success: true, provider: 'kakao' };
    } catch (error) {
      amplitude.track('Login', {
        'auth provider': 'kakao',
        err: error.message ?? 'Unexpected',
        success: false
      });
      console.error('[AuthContext] 커스텀 토큰 로그인 실패', error);
      return { success: false, provider: 'kakao', error };
    }
  }, []);

  /**
   * 사용자 로그아웃
   */
  const signOut = React.useCallback(async (): Promise<void> => {
    const auth = FirebaseAuth.getAuth();

    try {
      amplitude.setUserId(undefined);
      GoogleAnalytics.setUserId(null);
      window.clarity('set', 'userId', undefined);
    } catch (ce) {
      console.warn('Analytics 사용자 정보 제거 오류', ce);
    }
    try {
      removeCookie(SESSION_TOKEN);
    } catch (e2) {}

    try {
      await auth?.signOut();
      console.log('[AuthContext] 사용자 로그아웃 처리완료.');
    } catch (error) {
      console.warn('[AuthContext] 사용자 로그아웃 처리실패.');
    }
  }, []);

  /**
   * 사용자 정보 갱신
   */
  const refreshUser = async () => {
    console.log('[AuthContext] 사용자 정보 갱신 시작...');
    const auth = FirebaseAuth.getAuth();
    if (!auth.currentUser) {
      console.warn('로그인한 사용자가 아님');
      return false;
    }
    const result = await auth.currentUser
      .getIdTokenResult(true)
      .then(async (idTokenResult) => {
        const user: SignedInUser = {
          uid: auth.currentUser!.uid,
          displayName: auth.currentUser!.displayName,
          email: auth.currentUser!.email,
          emailVerified: auth.currentUser!.emailVerified,
          photoURL: auth.currentUser!.photoURL,
          god: idTokenResult.claims?.god === true
        };

        console.log('#갱신된 사용자 정보', user);
        dispatch(setFirebaseAuth({ user }));
        dispatch(closeSignInModalAction());

        // firestore에서 회원정보 가져오기
        const member: Member = await getDoc(doc(getFirestore(getApp()), 'member', user.uid)).then((docSnap) => {
          const { _id: uid, role, state, uname, nickname, my_taste, profile_img }: any = docSnap.data();
          return { uid, role, state, uname, nickname, my_taste, profile_img };
        });

        dispatch(setMember(member));
        return true;
      })
      .catch((error) => {
        console.warn('[AuthContext] 사용자 정보 갱신 실패', error);
        return false;
      });

    return result;
  };

  /** 전역 로그인 모달 열기 */
  const openSignInModal = () => {
    dispatch(openSignInModalAction());
  };

  /** 전역 로그인 모달 닫기 */
  const closeSignInModal = () => {
    dispatch(closeSignInModalAction());
  };

  return (
    <AuthContext.Provider
      value={{
        ...contextValue,
        signInWithEmailAndPassword,
        signInWithApple,
        signInWithCustomToken,
        signOut,
        refreshUser,
        openSignInModal,
        closeSignInModal
      }}
    >
      {children}

      <Dialog
        scroll="body"
        open={modals.isSignInModalOpen}
        fullWidth={isSmallScreen ?? false}
        onClose={closeSignInModal}
      >
        <SignIn onSuccess={() => closeSignInModal()} targetUrl={router.asPath} />
      </Dialog>
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext<ContextProps>(AuthContext);

export default AuthContext;
