import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const initialState: {
  initialized: boolean;
  state?: PermissionState | 'unavailable' | string;
  coords?: { latitude: number; longitude: number };
} = { initialized: false };

const geolocationSlice = createSlice({
  name: 'geolocation',
  initialState,
  reducers: {
    setGeolocationState(state, { payload }) {
      state.initialized = true;
      state.state = payload.state as string;
    },
    setPosition(state, { payload }) {
      // console.log('------------------> setPosition', payload);
      state.initialized = true;
      state.state = 'granted';

      const { latitude, longitude } = payload;
      state.coords = { latitude, longitude };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: any) => {
      console.log('############# GEOLOCATION HYDRATE ALARM #############', action.payload);
      // return { ...state, ...action.payload.alarm };
      return { ...state };
    });
  }
});

export const { setGeolocationState, setPosition } = geolocationSlice.actions;
export default geolocationSlice;
