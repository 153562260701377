import { FC } from 'react';
import Head from 'next/head';

// ====================================================================
type SEOProps = {
  title: string;
  sitename?: string;
  description?: string;
};
// ====================================================================

const SEO: FC<SEOProps> = ({ title, description = '', sitename = process.env.NEXT_PUBLIC_SITENAME as string }) => {
  const titleStr = title === '1KMWINE' ? '1KMWINE' : `${title} - ${sitename}`;
  return (
    <Head>
      <title>{titleStr}</title>
      <meta name="description" content={description} />
    </Head>
  );
};

export default SEO;
