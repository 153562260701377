import { PdataType } from './models/pdata/Pdata.types';

export const SERVICE_PROFILE = process.env.NEXT_PUBLIC_SERVICE_PROFILE as string;

/**
 * @type {boolean} 프로덕션에 배포된 인스턴스
 */
export const IS_PRODUCTION = process.env.NODE_ENV === 'production' && SERVICE_PROFILE === 'production';

export const PDATA_CATEGORY = Object.freeze({
  /** 와인 */
  WINE: { value: 'wine', label: { ko: '와인', en: 'Wine' } },
  /** 위스키 */
  WHISKEY: { value: 'whiskey', label: { ko: '위스키', en: 'Whiskey' } },
  /** 일본주 */
  NIHONSHU: { value: 'nihonshu', label: { ko: '일본주', en: 'Nihonshu' } },
  /** 티켓 */
  TICKET: { value: 'ticket', label: { ko: '티켓', en: 'Ticket' } },
  /** 기획상품 */
  OFFER: { value: 'offer', label: { ko: '기획상품', en: 'Offer' } }
});

// 쿠폰 타입
export const COUPON_TYPE = Object.freeze({
  ADMIN: 'ADMIN',
  VENDOR: 'VENDOR',
  ADIMN_TO_VENDOR: 'ADVEN'
});

export const SESSION_TOKEN = '__session';

/** 와인타입 */
export const WINE_TYPES: Record<string, { value: PdataType; label: { ko: string; en: string } }> = Object.freeze({
  /** 레드 */
  RED: { value: 'RED', label: { ko: '레드', en: 'Red' } },
  WHITE: { value: 'WHITE', label: { ko: '화이트', en: 'White' } },
  SPARKLING: { value: 'SPARKLING', label: { ko: '스파클링', en: 'Sparkling' } },
  ROSE: { value: 'ROSE', label: { ko: '로제', en: 'Rose' } },
  ETC: { value: 'ETC', label: { ko: '기타', en: 'Etc' } }
});

/** 위스키 타입 */
export const WHISKEY_TYPES = Object.freeze({
  SCOTCH: { value: 'SCOTCH', label: { ko: '스카치', en: 'Scotch' } },
  SINGLE_MALT: { value: 'SINGLE_MALT', label: { ko: '싱글 몰트', en: 'Single malt' } },
  ETC: { value: 'ETC', label: { ko: '기타', en: 'Etc' } }
});

/** 일본주 타입 */
export const NIHONSHU_TYPES = Object.freeze({
  ETC: { value: 'ETC', label: { ko: '기타', en: 'Etc' } }
});

/** 티켓 타입 */
export const TICKET_TYPES = Object.freeze({
  TASTING: { value: 'TASTING', label: { ko: '시음회', en: 'Tasting' } },
  CLASS: { value: 'CLASS', label: { ko: '클래스', en: 'Class' } },
  ETC: { value: 'ETC', label: { ko: '기타', en: 'Etc' } }
});
