import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import authActions from 'store/slices/wineone-auth';

export const initialState: any = { loading: false, error: null, total_vendors: 0, total_items: 0, items: [] };

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    /** 장바구니 상품목록 조회시작 */
    fetchCartItemsStart(state) {},
    /** 장바구니 상품목록 조회성공 */
    fetchCartItemsSuccess(state, action) {},
    /** 장바구니 상품목록 조회실패 */
    fetchCartItemsFailure(state, action) {},
    /** 장바구니 상품담기시작 */
    addCartItemStart(state, action) {},
    /** 장바구니 상품제거시작 */
    removeCartItemStart(state, action) {},

    /** todo 장바구니 초기화 */
    resetCart(state) {
      // console.log('todo 장바구니 초기화');
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action: any) => {
        // console.log('### HYDRATE CART ###', action.payload.cart);
        return { ...state, ...action.payload.cart };
      })
      // 사용자 로그아웃 때 장바구니 초기화
      .addCase(authActions.actions.setFirebaseAuthSignOut, (state, action) => {
        resetCart();
      });
  }
});

export const { addCartItemStart, removeCartItemStart, resetCart } = cartSlice.actions;
export default cartSlice;
