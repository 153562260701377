import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { FallbackProps } from 'react-error-boundary';

import cloClient from 'utils/clo.client';
import Header from 'components/header/Header';
import { FlexRowCenter } from 'components/flex-box';

import { Box, Button, Container, SvgIcon, Typography } from '@mui/material';

const UnexpectedError: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const router = useRouter();

  React.useEffect(() => {
    console.error('알 수 없는 오류.', error);

    try {
      cloClient('ERROR', {
        code: '531500',
        title: '[NEXTWEB] 알 수 없는 오류.',
        msg: error.message ?? 'Unexpected',
        which: window.location.href
      });
    } catch (se) {
      /* DO NOTHING */
    }
  }, []);

  // render
  return (
    <>
      <Head>
        <meta name="robots" content="noindex,nofollow" />
      </Head>
      <Header />
      <Container maxWidth="sm" sx={{ pt: 5, position: 'relative' }}>
        <Box pb="56px">
          <Box height={1} width={1} textAlign="center">
            <MaerongIcon />
          </Box>
          <Typography fontSize={16} fontWeight="bold" align="center" letterSpacing="-0.5px">
            이용에 불편을 드려 죄송합니다.
          </Typography>

          <Typography
            variant="body1"
            component="p"
            fontSize={14}
            lineHeight="20px"
            letterSpacing="-0.3px"
            fontWeight="normal"
            align="center"
            sx={{ mt: '12px' }}
          >
            데이터가 잘못 되었거나,
            <br />
            예상하지 못한 문제로 인해
            <br /> 현재 페이지를 불러올 수 없습니다.
          </Typography>
          {/*<Typography variant="subtitle1" fontWeight={800} align="center">*/}
          {/*  [error: {error.name}]*/}
          {/*</Typography>*/}
          <FlexRowCenter mt={4}>
            <Button
              type="button"
              variant="contained"
              color="darkGray"
              disableElevation
              onClick={() => {
                router.back();
                resetErrorBoundary();
              }}
              sx={{ minWidth: 120 }}
            >
              돌아가기
            </Button>
          </FlexRowCenter>
        </Box>
      </Container>
    </>
  );
};

const MaerongIcon = React.memo(() => (
  <SvgIcon viewBox="0 0 163 148" sx={{ width: 163, height: 148 }}>
    <path style={{ opacity: 0.01, fill: 'none' }} d="M0 0h163v148H0z" />
    <path
      d="M16820-4542v-3h3v3zm-6 0v-3h3v3zm3-3v-3h3v3zm3-3v-3h3v3zm-6 0v-3h3v3z"
      transform="translate(-16803 4562)"
      style={{ fill: '#220648' }}
    />
    <path
      d="M16820-4542v-3h3v3zm-6 0v-3h3v3zm3-3v-3h3v3zm3-3v-3h3v3zm-6 0v-3h3v3z"
      transform="translate(-16794 4613.002)"
      style={{ fill: '#220648' }}
    />
    <path
      d="M16820-4542v-3h3v3zm-6 0v-3h3v3zm3-3v-3h3v3zm3-3v-3h3v3zm-6 0v-3h3v3z"
      transform="translate(-16680 4597)"
      style={{ fill: '#220648' }}
    />
    <g>
      <path transform="translate(52 26)" style={{ fill: '#defd9a' }} d="M0 0h59v9H0z" />
      <g style={{ stroke: '#220348', strokeWidth: '3px', fill: 'none' }}>
        <path style={{ stroke: 'none' }} d="M0 0h65v109H0z" transform="translate(49 23)" />
        <path style={{ fill: 'none' }} d="M1.5 1.5h62v106h-62z" transform="translate(49 23)" />
      </g>
      <path transform="translate(51 35)" style={{ fill: '#220648' }} d="M0 0h61v3H0z" />
      <path transform="translate(54.998 29)" style={{ fill: '#220648' }} d="M0 0h3v3H0z" />
      <path transform="translate(60.998 29)" style={{ fill: '#220648' }} d="M0 0h3v3H0z" />
      <path transform="translate(66.998 29)" style={{ fill: '#220648' }} d="M0 0h3v3H0z" />
      <path transform="translate(67 87)" style={{ fill: '#220648' }} d="M0 0h29v3H0z" />
      <g>
        <path transform="rotate(-45 109.4 -45.192)" style={{ fill: '#220648' }} d="M0 0h3v13H0z" />
        <path transform="rotate(-135 48.244 22.963)" style={{ fill: '#220648' }} d="M0 0h3.001v13H0z" />
      </g>
      <g>
        <path transform="rotate(-45 121.24 -73.779)" style={{ fill: '#220648' }} d="M0 0h3v13H0z" />
        <path transform="rotate(-135 60.085 18.058)" style={{ fill: '#220648' }} d="M0 0h3.001v13H0z" />
      </g>
      <g style={{ fill: '#eadff5', stroke: '#220348', strokeWidth: '3px' }}>
        <path
          d="M0 0h9v11.5A4.5 4.5 0 0 1 4.5 16 4.5 4.5 0 0 1 0 11.5V0z"
          style={{ stroke: 'none' }}
          transform="translate(87 87)"
        />
        <path
          d="M1.5 1.5h6v10a3 3 0 0 1-3 3 3 3 0 0 1-3-3v-10z"
          style={{ fill: 'none' }}
          transform="translate(87 87)"
        />
      </g>
    </g>
  </SvgIcon>
));

export default UnexpectedError;
