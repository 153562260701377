import { Components, Theme } from '@mui/material';
import { dark, grey } from './themeColors';

// ========================================================
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    dark: true;
    paste: true;
    marron: true;
    lightGray: true;
    darkGray: true;
    brand: true;
  }

  interface ButtonPropsSizeOverrides {
    normal: true;
  }
}
// =========================================================

const dDinR = {
  fontFamily: 'D-DIN',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url('/static/fonts/D-DIN.otf') format('opentype'), url('/static/fonts/D-DIN.woff') format('woff')`
};

const dDinB = {
  fontFamily: 'DIN-Bold',
  fontStyle: 'normal',
  fontWeight: 800,
  src: `url('/static/fonts/D-DIN-Bold.otf') format('opentype'), url('/static/fonts/D-DIN-Bold.woff') format('woff')`
};

export const components: Components = {
  MuiCssBaseline: {
    styleOverrides: (theme: Theme) => ({
      html: [{ scrollBehavior: 'smooth' }, { '@font-face': dDinR }, { '@font-face': dDinB }],
      '.MuiRating-sizeSmall': {
        fontSize: '20px'
      },
      dl: {
        margin: 0
      },
      a: {
        textDecoration: 'none',
        color: 'inherit'
      },
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none'
      },
      '#nprogress .bar': {
        overflow: 'hidden',
        height: '3px !important',
        zIndex: '99999999 !important',
        background: `${theme.palette.primary.main} !important`
      }
    })
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: {
        marginTop: -1,
        lineHeight: '23px',
        fontWeight: 600
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: { zIndex: 0 }
    }
  },
  MuiDialog: {
    styleOverrides: {
      paper: { borderRadius: 8 }
    }
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: { letterSpacing: '-0.3px' }
    }
  },
  MuiCard: {
    defaultProps: { elevation: 0 },
    styleOverrides: {
      root: { borderRadius: '8px' }
    }
  },
  MuiPagination: {
    defaultProps: {
      variant: 'outlined',
      color: 'primary'
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: { paddingTop: 8, paddingBottom: 8 }
    }
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        '& .secondary': { opacity: 0.4 }
      }
    }
  },
  MuiTextField: {
    defaultProps: { size: 'small', variant: 'outlined' },
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.color === 'info' && {
          '& .MuiOutlinedInput-root': { borderRadius: '8px', fontWeight: 600 },
          '& .MuiOutlinedInput-notchedOutline': { borderColor: grey[300] }
        })
      })
    }
  },
  MuiTypography: {
    styleOverrides: {
      root: { letterSpacing: '-0.3px' }
    }
  },

  MuiButton: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        letterSpacing: '-0.3px'
      }),
      sizeLarge: { padding: '.6rem 2.5rem' }
    },
    defaultProps: { color: 'inherit' }
  }
};
