import React, { FC, Fragment, ReactElement, ReactNode } from 'react';

import Head from 'next/head';
import { NextPage } from 'next';
import Router, { useRouter } from 'next/router';
import { AppProps } from 'next/app';
import nProgress from 'nprogress';
import { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import RTL from 'components/RTL';
import MuiTheme from 'theme/MuiTheme';
import { AppProvider } from 'contexts/AppContext';
import SettingsProvider from 'contexts/SettingContext';
import SnackbarProvider from 'components/SnackbarProvider';
import createEmotionCache from 'createEmotionCache';

import { initializeApp } from 'firebase/app';
import { getAnalytics, setUserProperties } from '@firebase/analytics';
import { getPerformance } from '@firebase/performance';
import { IS_PRODUCTION, SESSION_TOKEN } from 'config';

import 'nprogress/nprogress.css';
import 'simplebar-react/dist/simplebar.min.css';
import { AuthProvider } from 'contexts/AuthContext';
import * as FirebaseAuth from '@firebase/auth';
import axios from 'axios';

import 'assets/scss/style.scss';
import 'components/vendor/orderStyle.scss';
import 'components/vendor/swiperStyle.scss';
import Script from 'next/script';
import { persistor, wrapper } from 'store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { KakaoPixel } from 'utils/analytics/kakao-pixel';
import { GoogleAnalytics } from 'utils/analytics/google-analytics';
import { useSearchParams } from 'next/navigation';
import SEO from '../src/components/SEO';
import nookies from 'nookies';
import * as amplitude from '@amplitude/analytics-browser';

import { ErrorBoundary } from 'react-error-boundary';
import UnexpectedError from 'pages-sections/error/UnexpectedError';

//Binding events.
Router.events.on('routeChangeStart', () => nProgress.start());
Router.events.on('routeChangeComplete', () => nProgress.done());
Router.events.on('routeChangeError', () => nProgress.done());
// small change
nProgress.configure({ showSpinner: false });

// Firebase client configuration
const firebaseConfig: any = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID
};

const isGoogleAnalyticsEnable = !!process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID;

// 상용기
if (isGoogleAnalyticsEnable) {
  firebaseConfig.measurementId = process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID;
}

// Initialize Firebase
const firebaseApp = (() => {
  try {
    if (typeof window !== 'undefined') {
      return initializeApp(firebaseConfig);
    }
  } catch (error) {
    console.error('Firebase initializeApp error', error, error.stack);
  }
})();

// Google Analytics user properties.
if (typeof window !== 'undefined' && isGoogleAnalyticsEnable) {
  setUserProperties(getAnalytics(firebaseApp), {
    app_name: '1kmwine.web.next',
    app_type: `1kmwine-next-web-user`
  });
}

// Initialize Performance Monitoring and get a reference to the service
if (typeof window !== 'undefined') {
  // Initialize Performance Monitoring and get a reference to the service
  const perf = getPerformance(firebaseApp);

  if (!IS_PRODUCTION) {
    perf.dataCollectionEnabled = false;
    perf.instrumentationEnabled = false;
  }

  console.debug('Firebase performance initialized.');
}

if (typeof window !== 'undefined') {
  // Amplitude Browser SDK 2.0
  // https://www.docs.developers.amplitude.com/data/sdks/browser-2/
  amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string, { defaultTracking: true });
  console.log('[amplitude] initialized.');
}

declare global {
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

// if (typeof window !== 'undefined') {
//   // App Check 디버그 토큰 사용 -> 개발자 로컬에 발급된 디버그 토큰을 firebase console에 등록합니다.
//   // 개발, 상용 서버에서 동작
//   if (process.env.NODE_ENV !== 'production') {
//     window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
//   }
//
//   // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
//   // key is the counterpart to the secret key you set in the Firebase console.
//   const appCheck = initializeAppCheck(firebaseApp, {
//     provider: new ReCaptchaV3Provider('6LfVoNUgAAAAAOZYcjsUnbQRnNWXqJ34XAjZmNwj'),
//     isTokenAutoRefreshEnabled: true // Optional argument. If true, the SDK automatically refreshes App Check tokens as needed.
//   });
//
//   console.debug('Firebase appCheck initialized.');
// }

// iOS Safari 100vh issue
// 100vh -> calc(var(--vh, 1vh) * 100)
if (typeof window !== 'undefined') {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  window.addEventListener('resize', () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  });
}

axios.interceptors.request.use(async (config) => {
  try {
    // 클라이언트 사이드에서 요청
    if (typeof window !== 'undefined') {
      const auth = FirebaseAuth.getAuth();
      const { [SESSION_TOKEN]: persistToken } = nookies.get(null);

      if (auth.currentUser) {
        const { accessToken, stsTokenManager }: any = auth.currentUser;
        let bearerToken = accessToken;

        // 토큰이 만료되었을 경우 갱신
        if (stsTokenManager.isExpired) {
          console.debug('[WEB] 사용자 토큰이 만료되어 갱신합니다.');
          const { token } = await auth.currentUser.getIdTokenResult(true);
          bearerToken = token;

          console.debug(`[WEB]  ㄴ 갱신된 토큰=${bearerToken}`);
        }

        config.headers.Authorization = `Bearer ${bearerToken}`;
      } else if (persistToken) {
        config.headers.Authorization = `Bearer ${persistToken}`;
      } else {
        delete config.headers.Authorization;
      }
    } else {
      // 서버 사이드에서 요청
    }
  } catch (e) {
    console.warn('axios interceptor 오류');
  }

  return config;
});

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPage & { getLayout?: (page: ReactElement) => ReactNode };
}

const WineoneApp: FC<MyAppProps> = ({ Component, emotionCache = clientSideEmotionCache, ...rest }: MyAppProps) => {
  const getLayout = Component.getLayout ?? ((page) => page);

  const router = useRouter();
  const searchParams = useSearchParams();
  // const { i18n, ...others } = useTranslation();

  // const { locale } = router;

  // React.useEffect(() => {
  //   i18n.changeLanguage?.(locale);
  // }, [i18n, locale]);

  React.useEffect(() => {
    const logPageViewEvent = (page_location: string): void => {
      GoogleAnalytics.logEvent('page_view', { page_location });
      KakaoPixel.pageView();
    };

    // 라우터 변경완료
    router.events.on('routeChangeComplete', logPageViewEvent);

    // 최초 1회 조회
    if (typeof window !== 'undefined') {
      const { pathname, search, hash } = window.location;
      GoogleAnalytics.logEvent('page_view', { page_location: `${pathname}${search}${hash}` });
    }

    return () => {
      router.events.off('routeChangeComplete', logPageViewEvent);
    };
  }, [router.events]);

  // Affiliates: 사용자 또는 입점샵 코드로 들어왔을 경우
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const shareType = searchParams.get('shr_type');

      // user 일 때는 친구코드(recommend_code)
      // vendor 일 때는 입점샵 아이디(vendor_id)
      if (shareType === 'user' || shareType === 'vendor') {
        try {
          const shareCode = searchParams.get('shr_code');
          if (shareCode) {
            // 24시간 유효
            const exp: number = Date.now() + 1000 * 60 * 60 * 24;
            window.localStorage.setItem('shr', JSON.stringify({ type: shareType, code: shareCode, exp }));

            // 주소창 URL 마스킹
            const maskedSearchParams = new URLSearchParams(window.location.search);
            maskedSearchParams.delete('shr_type');
            maskedSearchParams.delete('shr_code');
            const params = maskedSearchParams.toString();
            // console.log('#maskedSearchParams.toString(): ', maskedSearchParams.toString());

            const asPath = `${window.location.pathname}${params ? `?${params}` : ''}${window.location.hash}`;

            // URL 주소 마스킹
            router.replace(window.location.href, asPath, { shallow: true });
          }
        } catch (e) {
          console.error('[Affiliates] shr_code 저장실패', e);
        }
      }
    }
  }, []);

  const { store, props } = wrapper.useWrappedStore(rest);
  const meta = props.pageProps?.meta ?? {};
  // console.log('#meta: ', meta);

  // 카카오 스크립트 온로드 콜백
  const onKakaoScriptLoaded = React.useCallback(() => {
    if (typeof process.env.NEXT_PUBLIC_KAKAO_APP_KEY === 'string') {
      window.Kakao.init(process.env.NEXT_PUBLIC_KAKAO_APP_KEY);
      console.debug('[카카오 Auth] 스크립트 로드됨');
    } else {
      console.warn('[카카오 Auth] 카카오 스크립트트 세팅 오류 [KAKAO_APP_KEY not exists]');
    }
  }, []);

  // render
  return (
    <CacheProvider value={emotionCache}>
      <SEO
        title={meta.title ?? '와인과 일상을 잇다'}
        description={meta.description ?? '와인과 일상을 잇다. 당신 주변의 1KMWINE'}
      />
      <Head>
        <meta charSet="utf-8" />
        <meta name="copyright" content={`WINEONE INC. ${new Date().getFullYear()} ALL RIGHTS RESERVED.`} />
        <link rel="canonical" href={meta.canonical ?? process.env.NEXT_PUBLIC_SERVICE_HOST} key="canonical" />
        <meta name="naver-site-verification" content="dc3f16218e8532bcfeb4ae97cc77001f6f1a7bc7" />
        <meta name="google-site-verification" content="-J34l0ApN1V-ZsmPFGQWjVLA1S1D1RbrN01pKriYGBY" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="revisit-after" content="1 days" />
        {IS_PRODUCTION && !!meta.robots ? <meta name="robots" content={meta.robots} /> : null}

        {meta.og && (
          <Fragment>
            <meta property="og:type" content={meta.og.type ?? 'website'} />

            {meta.og.site_name && <meta property="og:site_name" content={meta.og.site_name} />}
            {meta.og.title && <meta property="og:title" content={meta.og.title} />}
            {meta.og.description && <meta property="og:description" content={meta.og.description} />}

            {meta.og.image ? (
              <Fragment>
                <meta property="og:image" content={meta.og.image} />
                {meta.og.image_width && meta.og.image_height ? (
                  <Fragment>
                    <meta property="og:image:width" content={`${meta.og.image_width}`} />
                    <meta property="og:image:height" content={`${meta.og.image_height}`} />
                  </Fragment>
                ) : null}
                {!!meta.og.image_alt && <meta property="og:image:alt" content={meta.og.image_alt ?? '400'} />}
              </Fragment>
            ) : null}
          </Fragment>
        )}

        <meta name="author" content={meta.author ?? '1KMWINE'} />
      </Head>

      <Script
        id="adsense-script"
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7233970740734143"
        crossOrigin="anonymous"
      />

      <Script
        src="//t1.kakaocdn.net/kakao_js_sdk/2.4.0/kakao.min.js"
        integrity="sha384-mXVrIX2T/Kszp6Z0aEWaA8Nm7J6/ZeWXbL8UpGRjKwWe56Srd/iyNmWMBhcItAjH"
        crossOrigin="anonymous"
        onLoad={onKakaoScriptLoaded}
      />

      <Script
        src={`//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.NEXT_PUBLIC_KAKAO_APP_KEY}&libraries=services,clusterer&autoload=false`}
      />

      <Script
        type="text/javascript"
        charSet="UTF-8"
        src="//t1.daumcdn.net/kas/static/kp.js"
        onLoad={() => {
          console.debug('[카카오 픽셀] 스크립트 로드됨');
          KakaoPixel.pageView(); // 최초 로드시 1회 -> 이후 routeChangeComplete 에서 동작
        }}
      />

      {/* Clarity tracking code for https://www.1kmwine.kr/ */}
      {IS_PRODUCTION && (
        <Script
          id="wineone-clarity"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i+"?ref=bwt";
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "k8t534kmxz");`
          }}
        />
      )}

      <Provider store={store} stabilityCheck="never">
        <PersistGate persistor={persistor} loading={null}>
          <MuiTheme>
            <ErrorBoundary FallbackComponent={UnexpectedError}>
              <AuthProvider>
                <AppProvider>
                  <SettingsProvider>
                    <SnackbarProvider>
                      <RTL>{getLayout(<Component {...props.pageProps} />)}</RTL>
                    </SnackbarProvider>
                  </SettingsProvider>
                </AppProvider>
              </AuthProvider>
            </ErrorBoundary>
          </MuiTheme>
        </PersistGate>
      </Provider>
    </CacheProvider>
  );
};

if (typeof window !== 'undefined') {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

// (WineoneApp as any).getInitialProps = wrapper.getInitialAppProps((store) => async (appCtx) => {
//   // You have to do dispatches first, before...
//   // await store.dispatch(fetchUserDevice());
//
//   // ...before calling (and awaiting!!!!) the children's getInitialProps
//   // @see https://nextjs.org/docs/advanced-features/custom-app#caveats
//   const childrenGip = await App.getInitialProps(appCtx);
//
//   return {
//     pageProps: {
//       // And you have to spread the children's GIP result into pageProps
//       // @see https://nextjs.org/docs/advanced-features/custom-app#caveats
//       ...childrenGip.pageProps,
//       test: 'eddie88cho'
//     }
//   };
// });

// export default wrapper.withRedux(appWithTranslation(WineoneApp));
// export default appWithTranslation(WineoneApp);
export default WineoneApp;
