// import { Noto_Sans_KR } from 'next/font/google';
//
// const notoSansKR = Noto_Sans_KR({
//   // preload: true, 기본값
//   subsets: ['latin'], // 또는 preload: false
//   weight: ['100', '300', '400', '500', '700', '900'] // 가변 폰트가 아닌 경우, 사용할 fontWeight 배열
// });
export const fontSize = 14;

export const typography = {
  fontSize,
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    'helvetica',
    '"Apple SD Gothic Neo"',
    '"나눔고딕"',
    'NanumSquareAc',
    // notoSansKR.style.fontFamily,
    // 'helvetica',
    'sans-serif',
    'Apple Color Emoji',
    '"Segoe UI"',
    'Segoe UI Emoji',
    'Segoe UI Symbol'
  ].join(','),
  htmlFontSize: 16,
  body1: { fontSize },
  body2: { fontSize }
};
