import { alpha } from '@mui/material/styles';

// =================================================================
interface CustomPaletteColor {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  600: string;
  700: string;
  800: string;
  900: string;
  main: string;
  contrastText: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    dark: CustomPaletteColor;
    paste: CustomPaletteColor;
    marron: CustomPaletteColor;
    lightGray: CustomPaletteColor;
    darkGray: CustomPaletteColor;
    brand: CustomPaletteColor;
  }

  interface PaletteOptions {
    dark: CustomPaletteColor;
    paste: CustomPaletteColor;
    marron: CustomPaletteColor;
    lightGray: CustomPaletteColor;
    darkGray: CustomPaletteColor;
    brand: CustomPaletteColor;
  }
}
// =================================================================

export const grey = {
  900: '#2B3445', // Main Text
  800: '#373F50', // Paragraph
  700: '#4B566B',
  600: '#7D879C', // Low Priority form Title/Text
  500: '#AEB4BE',
  400: '#DAE1E7', // Border
  300: '#E3E9EF',
  200: '#F3F5F9', // Line Stroke
  100: '#F6F9FC'
};

export const primary = {
  100: '#c1bdd7',
  200: '#9a93bc',
  300: '#746ba1',
  400: '#5c4c8f',
  500: '#442e7c',
  600: '#3f2874',
  700: '#371f69',
  800: '#2f155d',
  900: '#220348',
  main: '#220348',
  light: '#4E356C',
  dark: '#170232',
  contrastText: '#defd9a'
};

export const brand = {
  main: '#220348',
  light: '#882ef6',
  dark: '#110124',
  contrastText: '#defd9a'
};

export const secondary = {
  100: '#fad0bf',
  200: '#f7b296',
  300: '#f4956c',
  400: '#f37f4c',
  500: '#f26c2d',
  600: '#e76629',
  700: '#d95f25',
  800: '#cb5823',
  900: '#b14c1e',
  main: '#f26a2d',
  light: 'rgb(246, 115, 75)',
  dark: 'rgb(170, 56, 21)',
  contrastText: '#fff'
};

export const error = {
  100: '#FFEAEA',
  200: '#FFCBCB',
  300: '#FFA9A9',
  400: '#FF6D6D',
  500: '#FF5353',
  600: '#FF4C4C',
  700: '#FF4242',
  800: '#FF3939',
  900: '#FF2929',
  main: '#E94560'
};

export const success = {
  100: '#E7F9ED',
  200: '#C2F1D1',
  300: '#99E8B3',
  400: '#52D77E',
  500: '#33D067',
  600: '#2ECB5F',
  700: '#27C454',
  800: '#20BE4A',
  900: '#0b7724',
  main: '#259c24',
  light: '#81c784',
  text: '#fff'
};

export const blue = {
  50: '#f3f5f9',
  100: '#DBF0FE',
  200: '#B8DEFE',
  300: '#94C9FE',
  400: '#7AB6FD',
  500: '#4E97FD',
  600: '#3975D9',
  700: '#2756B6',
  800: '#183C92',
  900: '#0E2979',
  main: '#4E97FD',
  contrastText: '#FFFFFF'
};

export const marron = {
  50: '#f3f5f9',
  100: '#F6F2ED',
  200: '#F8DBD1',
  300: '#EBBCB3',
  400: '#D89C98',
  600: '#A3545C',
  700: '#883948',
  800: '#6E2438',
  900: '#5B162F',
  main: '#BE7374'
};

export const paste = {
  50: '#F5F5F5',
  100: '#DDFBF1',
  200: '#BDF7E8',
  300: '#97E8DA',
  400: '#76D2CA',
  600: '#36929A',
  700: '#257181',
  800: '#175368',
  900: '#0E3D56',
  main: '#4BB4B4',
  contrastText: '#FFFFFF'
};

export const warning = {
  100: '#FFF8E5',
  main: '#FFCD4E',
  contrastText: '#FFFFFF'
};

export const lightGray = {
  100: grey[100],
  main: '#dcdee0',
  contrastText: '#FFFFFF'
};

export const darkGray = {
  main: '#F3F5F7',
  contrastText: '#404048'
};

export const dark = { main: '#222' };
export const white = { main: '#fff' };

export const themeColors = {
  dark,
  grey,
  paste,
  error,
  brand,
  primary,
  marron,
  warning,
  success,
  secondary,
  info: blue,
  lightGray,
  darkGray,
  divider: 'rgba(45,21,77,0.1)',
  background: {
    paper: '#ffffff',
    default: '#ffffff',
    level2: '#f5f5f5',
    level1: '#ffffff',
    product: '#f4f0f8',
    order: '#f6f5f8',
    contrast1: '#f3f2f5',
    pdata: {
      default: '#f4f0f8',
      wine: '#f4f0f8'
    }
  },
  text: {
    primary: '#1e1e23',
    brand: '#defd9a',
    secondary: alpha('#140229', 0.5),
    link: '#1976d2',
    white: '#ffffff'
  }
};
