import { components } from './components';
import { primary, themeColors } from './themeColors';
import { typography } from './typography';

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
  }
};

const themeOptions = (pathname: string) => {
  return {
    typography,
    breakpoints,
    components: { ...components },
    // @ts-ignore
    palette: { primary: { ...primary, light: primary.light }, ...themeColors }
  };
};

export default themeOptions;
