import { combineReducers, Store } from 'redux';
import { Action, configureStore, EnhancedStore, ThunkAction } from '@reduxjs/toolkit';
import { createWrapper, MakeStore } from 'next-redux-wrapper';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authSlice from 'store/slices/wineone-auth';
import deviceSlice from 'store/slices/device';
import alarmSlice from 'store/slices/alarm';
import cartSlice from 'store/slices/cart';
import modalsSlice from 'store/slices/global-modals';
import geolocationSlice from 'store/slices/geolocation';
import tempOrderSlice from 'store/slices/temp-order';

const reducers = {
  [alarmSlice.name]: alarmSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [cartSlice.name]: cartSlice.reducer,
  [deviceSlice.name]: deviceSlice.reducer,
  [modalsSlice.name]: modalsSlice.reducer,
  [geolocationSlice.name]: geolocationSlice.reducer,
  [tempOrderSlice.name]: tempOrderSlice.reducer
};

const persistConfig = {
  key: 'nextjs',
  whitelist: [authSlice.name, geolocationSlice.name, tempOrderSlice.name],
  version: 1,
  storage
};

const rootReducer = combineReducers(reducers);
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    });

    // if (process.env.NODE_ENV !== 'production') middleware.concat(logger);

    return middleware;
  },
  devTools: process.env.NODE_ENV !== 'production'
});

const setupStore = (context: any): EnhancedStore => store;
const makeStore: MakeStore<any> = (context: any) => setupStore(context);
export const persistor = persistStore(store);

type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const wrapper = createWrapper<Store>(makeStore, { debug: false });
