import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import authActions from 'store/slices/wineone-auth';

export const initialState: any = { loading: false, error: null, alarms: [] };

const alarmSlice = createSlice({
  name: 'alarm',
  initialState,
  reducers: {
    /** todo 알림영역 초기화 */
    resetAlarm(state) {
      // console.log('todo 알림 초기화');
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action: any) => {
        // console.log('### HYDRATE ALARM ###', action.payload.alarm);
        return { ...state, ...action.payload.alarm };
      })
      // 사용자 로그아웃 때 알림 초기화
      .addCase(authActions.actions.setFirebaseAuthSignOut, (state, action) => {
        resetAlarm();
      });
  }
});

export const { resetAlarm } = alarmSlice.actions;
export default alarmSlice;
