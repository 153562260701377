import React, { FC, Fragment, ReactElement, ReactNode } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import clsx from 'clsx';

// project imports
import { layoutConstant } from 'utils/constants';
import { useAuthContext } from 'contexts/AuthContext';
import Image from 'components/BazaarImage';
import MiniCart from 'components/MiniCart';
import Category from 'components/icons/Category';
import { Paragraph } from 'components/Typography';
import { FlexBetween, FlexBox } from 'components/flex-box';
import CategoryMenu from 'components/categories/CategoryMenu';

// material-ui
import {
  Avatar,
  AvatarProps,
  Badge,
  Box,
  Button,
  ButtonProps,
  Divider,
  Drawer,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
  SvgIcon,
  SvgIconProps,
  Tooltip,
  Typography
} from '@mui/material';
import BallotIcon from '@mui/icons-material/Ballot';
import Container from '@mui/material/Container';
import { alpha, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Clear, KeyboardArrowDown, Logout } from '@mui/icons-material';

// assets
import Event from '../icons/Event';

// styled component
export const HeaderWrapper = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  zIndex: 3,
  position: 'relative',
  height: layoutConstant.headerHeight,
  transition: 'height 250ms ease-in-out',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    height: layoutConstant.mobileHeaderHeight
  }
}));

// 헤더 전용 아바타 - 모바일
const MobileAvatar = styled<FC<AvatarProps>>(Avatar)({
  height: 32,
  width: 32,
  fontSize: '0.85rem'
});

// 헤더 전용 아바타
const DesktopAvatar = styled<FC<AvatarProps>>(Avatar)({
  height: 36,
  width: 36,
  fontSize: '1rem'
});

const StyledContainer = styled(Container)({
  gap: 2,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});

// ==============================================================
type HeaderProps = {
  isFixed?: boolean;
  className?: string;
  searchInput?: ReactElement;
  showSearchBar?: boolean;
};
// ==============================================================

const Header: FC<HeaderProps> = ({ isFixed, className, searchInput, showSearchBar = false }) => {
  const theme = useTheme();
  const router = useRouter();

  const { state: authState, signOut, openSignInModal, closeSignInModal } = useAuthContext();

  // const [dialogOpen, setDialogOpen] = useState(false);
  const [sidenavOpen, setSidenavOpen] = React.useState(false);
  const [searchBarOpen, setSearchBarOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const downMd = useMediaQuery(theme.breakpoints.down(1150));

  // example: 사이드 메뉴 열기
  const toggleSidenav = () => setSidenavOpen(!sidenavOpen);
  // example: 검색창 열기
  const toggleSearchBar = () => setSearchBarOpen(!searchBarOpen);

  /** 사용자 로그아웃 처리 */
  const memberSignOut = React.useCallback(async (): Promise<void> => {
    handleClose();
    if (window.confirm('로그아웃 하시겠습니까?')) {
      await signOut();
    }
  }, [signOut]);

  // 신??
  const isGod = React.useMemo(() => {
    try {
      return authState?.isLoggedIn && authState?.user?.god;
    } catch (e) {
      return false;
    }
  }, [authState.user]);

  // LOGIN AND MINICART DRAWER
  const DIALOG_DRAWER = (
    <Fragment>
      {/*<Dialog scroll="body" open={dialogOpen} fullWidth={isMobile} onClose={toggleDialog} sx={{ zIndex: 9999 }}>*/}
      {/*  <SignIn onSuccess={() => setDialogOpen(false)} targetUrl={router.asPath} />*/}
      {/*</Dialog>*/}

      <Drawer open={sidenavOpen} anchor="right" onClose={toggleSidenav} sx={{ zIndex: 9999 }}>
        <MiniCart toggleSidenav={toggleSidenav} />
      </Drawer>
    </Fragment>
  );

  const MEMBER_MENU = (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      sx={{ zIndex: 1601 }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
          }
        }
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {/*<MenuItem*/}
      {/*  onClick={() => {*/}
      {/*    router.push('/my');*/}
      {/*    // handleClose();*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Avatar /> 마이 페이지*/}
      {/*</MenuItem>*/}

      <MenuItem
        onClick={() => {
          router.push('/my/order/list');
        }}
      >
        <ListItemIcon>
          <BallotIcon fontSize="small" />
        </ListItemIcon>
        주문목록
      </MenuItem>
      <Divider />

      <MenuItem onClick={memberSignOut}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        로그아웃
      </MenuItem>
    </Menu>
  );

  // FOR SMALLER DEVICE
  if (downMd) {
    const ICON_STYLE = { color: 'grey.600', fontSize: 20 };

    // render small device
    return (
      <HeaderWrapper component="header" className={clsx(className)}>
        <StyledContainer>
          <FlexBetween width="100%">
            {/* LEFT CONTENT - NAVIGATION ICON BUTTON */}
            {/*<Box flex={1}>/!*<MobileMenu />*!/</Box>*/}

            {/* MIDDLE CONTENT - LOGO */}
            <Link href="/">
              <Image width={100} src="/assets/images/logo-dark.png" alt="logo" />
            </Link>

            {/* RIGHT CONTENT - LOGIN, CART, SEARCH BUTTON */}

            <FlexBox justifyContent="end" flex={1}>
              {/*{showSearchBar && (*/}
              {/*  <Box component={IconButton} onClick={toggleSearchBar}>*/}
              {/*    <Icon.Search sx={ICON_STYLE} />*/}
              {/*  </Box>*/}
              {/*)}*/}
              {/*모바일 이벤트 버튼*/}
              {/*<Box py={1}>*/}
              {/*  <EventButton*/}
              {/*    size="small"*/}
              {/*    onClick={() => {*/}
              {/*      router.push('/event');*/}
              {/*    }}*/}
              {/*    sx={{ pt: '11px', height: '32px' }}*/}
              {/*  />*/}
              {/*</Box>*/}

              {/* 프로필 이미지 - 모바일 */}
              {authState.isLoggedIn ? (
                <IconButton
                  onClick={handleClick}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <GodBadge isGod={isGod}>
                    {authState.user?.photoURL ? (
                      <MobileAvatar src={authState.user.photoURL} />
                    ) : (
                      <MobileAvatar {...stringAvatar(authState.user.displayName)} />
                    )}
                  </GodBadge>
                </IconButton>
              ) : (
                <Box component={IconButton} onClick={() => openSignInModal()}>
                  <MobileAvatar />
                </Box>
              )}

              {/* 장바구니 버튼 - 모바일 */}
              {/*<Box component={IconButton} onClick={toggleSidenav}>*/}
              {/*  <Badge badgeContent={state.cart.length} color="primary">*/}
              {/*    <Icon.CartBag sx={ICON_STYLE} />*/}
              {/*  </Badge>*/}
              {/*</Box>*/}
            </FlexBox>
          </FlexBetween>

          {/* SEARCH FORM DRAWER */}
          <Drawer open={searchBarOpen} anchor="top" onClose={toggleSearchBar} sx={{ zIndex: 9999 }}>
            <Box sx={{ width: 'auto', padding: 2, height: '100vh' }}>
              <FlexBetween mb={1}>
                <Paragraph>Search to Bazaar</Paragraph>

                <IconButton onClick={toggleSearchBar}>
                  <Clear />
                </IconButton>
              </FlexBetween>

              {/* CATEGORY BASED SEARCH FORM */}
              {searchInput}
            </Box>
          </Drawer>

          {/* LOGIN FORM DIALOG AND CART SIDE BAR  */}
          {DIALOG_DRAWER}
          {MEMBER_MENU}
        </StyledContainer>
      </HeaderWrapper>
    );
  }

  // render large
  return (
    <HeaderWrapper component="header" className={clsx(className)}>
      <StyledContainer maxWidth="lg">
        {/* LEFT CONTENT - LOGO AND CATEGORY */}
        <FlexBox mr={2} minWidth="170px" alignItems="center">
          <Link href="/">
            <Image width={120} src="/assets/images/logo-dark.png" alt="logo" />
          </Link>

          {/* SHOW DROP DOWN CATEGORY BUTTON WHEN HEADER FIXED */}
          {isFixed && (
            <CategoryMenu>
              <FlexBox color="grey.600" alignItems="center" ml={2}>
                <Button color="inherit">
                  <Category fontSize="small" color="inherit" />
                  <KeyboardArrowDown fontSize="small" color="inherit" />
                </Button>
              </FlexBox>
            </CategoryMenu>
          )}
        </FlexBox>

        {/* SEARCH FORM */}
        {showSearchBar && (
          <FlexBox justifyContent="center" flex="1 1 0">
            {searchInput}
          </FlexBox>
        )}

        {/* LOGIN AND CART BUTTON */}
        <FlexBox gap={2} alignItems="center">
          {/*<EventButton*/}
          {/*  onClick={() => {*/}
          {/*    router.push('/event');*/}
          {/*  }}*/}
          {/*/>*/}

          {/* 사용자 프로필 이미지 - 데스크탑 */}
          {authState.isLoggedIn ? (
            <Tooltip title={authState.user?.displayName}>
              <IconButton
                sx={{ p: 0 }}
                onClick={handleClick}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <GodBadge isGod={isGod}>
                  {authState.user?.photoURL ? (
                    <DesktopAvatar src={authState.user.photoURL} />
                  ) : (
                    <DesktopAvatar {...stringAvatar(authState.user.displayName)} />
                  )}
                </GodBadge>
              </IconButton>
            </Tooltip>
          ) : (
            <Box component={IconButton} p={0} onClick={() => openSignInModal()}>
              <DesktopAvatar />
            </Box>
          )}

          {/*장바구니버튼 - 데스크탑*/}
          {/*<Badge badgeContent={state.cart.length} color="primary">*/}
          {/*  <Box p={1.25} bgcolor="grey.200" component={IconButton} onClick={toggleSidenav}>*/}
          {/*    <ShoppingBagOutlined />*/}
          {/*  </Box>*/}
          {/*</Badge>*/}
        </FlexBox>

        {/* LOGIN FORM DIALOG AND CART SIDE BAR  */}
        {DIALOG_DRAWER}
        {MEMBER_MENU}
      </StyledContainer>
    </HeaderWrapper>
  );
};

export default Header;

const EventButtonBase = styled(Button)({
  padding: '13px 20px 11px 20px',
  borderRadius: '40px',
  backgroundColor: alpha('#220348', 0.06)
});

const EventButton = React.memo((props: ButtonProps) => (
  <EventButtonBase variant="contained" startIcon={<Event />} disableElevation {...props}>
    이벤트/혜택
  </EventButtonBase>
));
EventButton.displayName = 'HeaderEventButton';

const GodBadge = React.memo(({ children, isGod }: { children: ReactNode; isGod: boolean }) => (
  <Badge
    color="primary"
    overlap="circular"
    badgeContent={
      <Typography fontSize="12px" sx={{ pt: '2px' }}>
        神
      </Typography>
    }
    invisible={!isGod}
  >
    {children}
  </Badge>
));
GodBadge.displayName = 'GodBadge';

function stringToColor(string: string) {
  if (!string) return '#000000';
  let hash = 0;
  let i: number;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

function invertColor(hex: string) {
  if (hex.indexOf('#') === 0) hex = hex.slice(1);
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    console.error('Invalid HEX color.');
    return 'inherit';
  }
  // invert color components
  const r: string = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g: string = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b: string = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return `#${padZero(r)}${padZero(g)}${padZero(b)}`;
  function padZero(str: string, len: number = 2): string {
    const zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }
}

function stringAvatar(name: string) {
  if (!name) return '';
  const bgcolor = stringToColor(name);
  // const color = invertColor(bgcolor);
  return {
    // sx: { color, bgcolor },
    sx: { bgcolor },
    children: `${name[0]}${name[1]}`
  };
}
