type KakaoFuncName =
  | 'addToCart'
  | 'addToWishList'
  | 'login'
  | 'completeRegistration' // 회원가입
  | 'missionComplete'
  | 'pageView'
  | 'participation'
  | 'preparation'
  | 'purchase'
  | 'purchaseNaverCheckout'
  | 'purchasePaycoCheckout'
  | 'search'
  | 'signUp' // 서비스 신청
  | 'viewCart'
  | 'viewContent';

/**
 * 카카오 픽셀
 * - https://business.kakao.com/pixel/detail/8242355448676761605?listPage=0&tab=info
 */
export class KakaoPixel {
  /** 카카오 픽셀 아이디 */
  private static pixelId: string = process.env.NEXT_PUBLIC_KAKAO_PIXEL_ID as string;
  private static tagName: string = 'winepedia';

  /** 방문 */
  static pageView() {
    this.emitFunc('pageView', this.tagName);
  }

  static login() {
    this.emitFunc('login', this.tagName);
  }

  /** 검색 */
  static search({ keyword, tag }: { keyword: string; tag: string }) {
    this.emitFunc('search', { keyword, tag });
  }

  /**
   * 콘텐츠/상품 조회 이벤트
   * @param id 상품고유값
   * @param tag 태그값
   */
  static viewContent({ id, tag }: { id: string; tag: 'pdata' | 'product' | 'cobuying' | 'vendor' | 'event' }) {
    this.emitFunc('viewContent', { id, tag });
  }
  /** 회원가입 */
  static completeRegistration({ method }: { method: 'email' | 'kakao' | 'apple' }) {
    this.emitFunc('completeRegistration', method);
  }
  /** 구매 */
  static purchase(eventParams: {
    tag?: string;
    total_quantity: number;
    total_price: number;
    products: { id: string; name: string; quantity: number; price: number }[];
  }) {
    this.emitFunc('purchase', {
      total_quantity: `${eventParams.total_quantity}`,
      total_price: `${eventParams.total_price}`,
      currency: 'KRW',
      products: eventParams.products.map((product) => ({
        id: product.id,
        name: product.name,
        quantity: `${product.quantity}`,
        price: `${product.price}`
      }))
    });
  }

  private static emitFunc(funcName: KakaoFuncName, ...args: any[]) {
    if (typeof window === 'undefined') return;
    if (typeof window.kakaoPixel !== 'function') {
      console.warn('카카오 픽셀 초기화 안됨');
      return;
    }
    console.debug('[kakao-pixel] emitFunc', this.pixelId, funcName, ...args);
    window.kakaoPixel(this.pixelId)[funcName](...args);
  }
}
