import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { UserInfo } from '@firebase/auth';
import nookies from 'nookies';
import { SESSION_TOKEN } from '../../config';

export interface AuthState {
  isLoggedIn: boolean;
  user: UserInfo & any;
  member?: any;
}

export const initialState: AuthState = { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    /** 사용자 firebase authentication 처리 */
    setFirebaseAuth(state, action) {
      const { user }: AuthState = action.payload;
      console.debug('[wineone-auth] firebase auth 정보 저장');
      state.isLoggedIn = true; // firebase 로그인 여부
      state.user = user; // firebase 사용자 정보
    },
    /** firestore에 저장된 member 정보 */
    setMember(state, action) {
      console.debug('[wineone-auth] member 정보 저장');
      state.member = action.payload; // firestore에 저장된 member 정보
    },

    /** firebase auth 정보 날리기 */
    setFirebaseAuthSignOut(state) {
      console.debug('[wineone-auth] 로그아웃 처리');
      state.isLoggedIn = false; // firebase 로그인 여부
      state.user = null; // firebase 사용자 정보

      if (typeof window !== 'undefined') {
        nookies.destroy(null, SESSION_TOKEN);
      }

      delete state.member;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: any) => {
      console.log('### HYDRATE AUTH ###', action.payload);
      return { ...state, ...action.payload.auth };
    });
  }
});

export const { setFirebaseAuth, setMember, setFirebaseAuthSignOut } = authSlice.actions;
export default authSlice;
