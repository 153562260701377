import { getApp } from 'firebase/app';
import { CustomEventName, getAnalytics, logEvent, setUserId } from '@firebase/analytics';

export class GoogleAnalytics {
  static logEvent(eventName: CustomEventName<string>, eventParams?: Record<string, any>) {
    try {
      console.debug('[google-analytics] logEvent', eventName, eventParams);
      logEvent(getAnalytics(getApp()), eventName, eventParams);
    } catch (e) {
      console.warn('[google-analytics] logEvent error', e);
    }
  }

  static setUserId(userId: string | null) {
    console.debug('[google-analytics] setUserId', userId);
    setUserId(getAnalytics(getApp()), userId, { global: true });
  }
}
