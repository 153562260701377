import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const Event = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <g transform="translate(-1.25 -1.25)">
        <rect width="20" height="6.083" rx="2" transform="translate(1.25 5.889)" fill="#220348" />
        <path
          d="M10.7,13.736v8.422H5.323A2.572,2.572,0,0,1,2.75,19.585V13.53a3.454,3.454,0,0,0,1.17.206Z"
          transform="translate(-0.096 -0.908)"
          fill={props.color ?? '#220348'}
        />
        <path
          d="M20.7,13.53v6.055a2.572,2.572,0,0,1-2.573,2.573H12.75V13.736h6.785A3.454,3.454,0,0,0,20.7,13.53Z"
          transform="translate(-0.858 -0.908)"
          fill={props.color ?? '#220348'}
        />
        <path
          d="M15.227,7.333H11.952a.7.7,0,0,1-.688-.842l.476-2.384A3.565,3.565,0,0,1,15.227,1.25a3.041,3.041,0,0,1,0,6.083Zm-2.419-1.4h2.419a1.638,1.638,0,0,0,0-3.275,2.157,2.157,0,0,0-2.11,1.731Z"
          transform="translate(-0.728 0)"
          fill={props.color ?? '#220348'}
        />
        <path
          d="M11.567,7.333H8.291a3.041,3.041,0,1,1,0-6.083,3.565,3.565,0,0,1,3.487,2.859l.476,2.384a.7.7,0,0,1-.688.842ZM8.291,2.654a1.638,1.638,0,1,0,0,3.275H10.71L10.4,4.385A2.157,2.157,0,0,0,8.291,2.654Z"
          transform="translate(-0.291 0)"
          fill={props.color ?? '#220348'}
        />
      </g>
    </SvgIcon>
  );
};

export default Event;
