import React, { FC } from 'react';
import { alpha, Box, Button, Card, CardProps, Divider, Link, Stack, styled, SvgIcon, Typography } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { H6 } from 'components/Typography';
import BazaarImage from 'components/BazaarImage';
import Image from 'components/BazaarImage';
import BazaarTextField from 'components/BazaarTextField';
import EyeToggleButton from './EyeToggleButton';
import { FlexBox, FlexRowCenter } from 'components/flex-box';
import { LoadingButton } from '@mui/lab';
import { useAuthContext } from 'contexts/AuthContext';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { isFunction } from 'lodash';
import * as FirebaseAuth from '@firebase/auth';

const kakaoStyle = { background: '#FEE500', color: alpha('#000000', 0.85) };
const appleStyle = { background: 'black', color: 'white' };

type WrapperProps = { passwordVisibility?: boolean };

export const Wrapper = styled<FC<WrapperProps & CardProps>>(({ children, passwordVisibility, ...rest }) => (
  <Card {...rest}>{children}</Card>
))<CardProps>(({ theme, passwordVisibility }) => ({
  width: 500,
  padding: '2rem 3rem',
  [theme.breakpoints.down('sm')]: { width: '100%', padding: '2rem 1rem' },
  '.passwordEye': {
    color: passwordVisibility ? theme.palette.grey[600] : theme.palette.grey[400]
  },
  '.kakaoButton': { ...kakaoStyle, '&:hover': kakaoStyle },
  '.appleButton': { ...appleStyle, '&:hover': appleStyle }
}));

type SignInProps = {
  onSuccess?: () => void | Promise<void>;
  targetUrl?: string | undefined; // 로그인 성공 후 돌아갈 페이지 URL
};

/**
 * 로그인 영역
 * @param onSuccess 로그인 성공 콜백
 * @param targetUrl 로그인 성공 후 넘어갈 페이지 URL
 * @constructor
 */
const SignIn: FC<SignInProps> = ({ onSuccess, targetUrl }) => {
  const [passwordVisibility, setPasswordVisibility] = React.useState<boolean>(false);

  const togglePasswordVisibility = React.useCallback((): void => {
    setPasswordVisibility((visible: boolean) => !visible);
  }, []);

  const { signInWithEmailAndPassword, signInWithApple, closeSignInModal } = useAuthContext();

  const router = useRouter();

  const handleFormSubmit = async (values: any, { setSubmitting }): Promise<void> => {
    const result = await signInWithEmailAndPassword(values.email, values.password);
    console.log('# 로그인 결과', result);

    // 로그인 성공 -> 메인 페이지로 이동
    if (result.success) {
      if (isFunction(onSuccess)) {
        await onSuccess();
      } else {
        console.log('################## emailpassword onSuccess default');
      }
    } else {
      const { code } = result.error;
      let errorMessage: string = '로그인 중 오류가 발생했습니다.\r\n잠시 후 다시 시도해주세요';

      // 비밀번호 틀림 || 아이디 없음
      if (code === 'auth/wrong-password' || code === 'auth/user-not-found') {
        errorMessage = '아이디 또는 비밀번호가 맞지 않습니다.';
      } else if (code === 'auth/internal-error') {
        errorMessage = '로그인 중 오류가 발생했습니다.\r\n잠시 후 다시 시도해주세요\r\n[인증서버 오류]';
      } else {
        console.warn('이메일 로그인 실패코드 error.code: ', code);
      }

      window.alert(errorMessage);
    }

    setSubmitting(false);
  };

  const handleApple = async (): Promise<void> => {
    const result = await signInWithApple();
    console.debug('[Sign-in][handleApple] 애플 로그인 결과', result);
    if (result?.success) {
      if (isFunction(onSuccess)) {
        await onSuccess();
      } else {
        console.log('################## apple onSuccess default');
      }
    } else if (result.error) {
      const { code, cause } = result.error;
      console.log('#code: ', code, cause);

      if (cause === 'wineone/not-member') {
        console.warn('# SNS 로그인만 하고 회원가입하지 않은 apple 사용자');
        if (confirm('회원가입하지 않은 Apple 계정입니다. 회원가입 하시겠습니까?')) {
          let destination = '/auth/sign-up/apple';
          if (targetUrl && targetUrl !== '/') {
            destination += `?targetUrl=${encodeURIComponent(targetUrl)}`;
          }
          await router.push(destination);
        } else {
          // firebase auth 로그아웃 처리
          await FirebaseAuth.getAuth()
            .signOut()
            .then(() => {
              console.log('회원가입 거절 apple 사용자 로그아웃처리');
            });
        }
      } else if (
        code === 'auth/cancelled-popup-request' ||
        code === 'auth/popup-closed-by-user' ||
        code === 'auth/user-cancelled'
      ) {
        /*
         * auth/cancelled-popup-request = 사용자가 팝업을 닫음
         * auth/popup-closed-by-user = 사용자가 팝업을 닫음
         * auth/user-cancelled = 사용자가 로그인을 취소함
         */
        console.log('################################### 애플 로그인 실패', code, cause);
        // alert('Apple 로그인에 실패했습니다.');
      } else {
        alert('로그인 중 오류가 발생했습니다.\r\n잠시 후 다시 시도해주세요');
      }
    }
  };

  const handleKakao = () => {
    if (!window.Kakao.isInitialized()) {
      window.alert('아직 카카오 모듈이 준비되지 않았습니다.');
      return;
    }
    const { Kakao } = window;
    // https://developers.kakao.com/sdk/reference/js/release/Kakao.Auth.html#.authorize
    Kakao.Auth.authorize({
      redirectUri: `${window.origin}/auth/sign-in/kakao/callback`,
      prompt: 'select_account',
      throughTalk: true,
      state: JSON.stringify({ targetUrl })
    });
  };

  const initialValues = React.useMemo(() => {
    return { email: router.query?.ev ?? '', password: '' };
  }, []);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: formSchema
  });

  // 회원가입 페이지로 이동
  const moveToSignUpPage = React.useCallback(() => {
    closeSignInModal();
    const signUpPageUrl = '/auth/sign-up';

    const searchParams = new URLSearchParams();
    if (targetUrl && targetUrl !== '/') searchParams.set('targetUrl', targetUrl);

    const destination = `${signUpPageUrl}?${searchParams.toString()}`;
    console.log('[Sign-in] 회원가입 페이지로 이동.', destination);
    router.push(destination);
  }, []);

  // render
  return (
    <>
      <Head>
        <meta name="robots" content="noindex,nofollow" />
        <title>회원 로그인 - 1KMWINE</title>
      </Head>

      <Wrapper elevation={3} passwordVisibility={passwordVisibility}>
        <BazaarImage
          width={200}
          src="/assets/images/logo-dark.png"
          sx={{ m: 'auto', cursor: 'pointer' }}
          onClick={() => {
            router.replace('/');
          }}
        />

        <Typography component="h3" textAlign="center" mt={1} mb={4} fontSize="14px" letterSpacing="-0.5px">
          1KMWINE에 오신 것을 환영합니다
        </Typography>

        <Button type="button" className="kakaoButton" size="medium" onClick={handleKakao} fullWidth sx={{ height: 44 }}>
          <SvgIcon viewBox="0 0 48 48" sx={{ width: 48, height: 48 }}>
            <path
              d="M11.5 0C5.148 0 0 4.059 0 9.067c0 3.233 2.153 6.082 5.391 7.683-.176.607-1.132 3.908-1.17 4.168 0 0-.023.195.1.269a.347.347 0 0 0 .279.013c.36-.048 4.2-2.74 4.863-3.2a14.547 14.547 0 0 0 2.037.138c6.352 0 11.5-4.059 11.5-9.067S17.852 0 11.5 0z"
              transform="translate(12.5 14)"
              style={{ fill: '#3a2929' }}
            />
          </SvgIcon>
          <Box fontSize={14} ml={-0.5}>
            카카오로 시작하기
          </Box>
        </Button>

        <Button
          type="button"
          className="appleButton"
          size="medium"
          onClick={handleApple}
          fullWidth
          sx={{ mt: 1.5, height: 44 }}
        >
          <Image src="/assets/images/icons/apple-white-1.svg" height="20px" alt="apple" />
          <Box fontSize={14} ml={1}>
            Apple로 로그인하기
          </Box>
        </Button>

        <Box mb={3} mt={3.8}>
          <Box width="200px" mx="auto">
            <Divider />
          </Box>

          <FlexBox justifyContent="center" mt={-1.625}>
            <Box color="grey.600" bgcolor="background.paper" px={2}>
              or
            </Box>
          </FlexBox>
        </Box>

        <form onSubmit={handleSubmit}>
          <BazaarTextField
            mb={1.5}
            fullWidth
            name="email"
            size="small"
            type="email"
            variant="outlined"
            onBlur={handleBlur}
            value={values.email}
            onChange={handleChange}
            label="이메일 주소"
            placeholder="exmple@mail.com"
            error={!!touched.email && !!errors.email}
            helperText={(touched.email && errors.email) as string}
          />

          <BazaarTextField
            mb={2}
            fullWidth
            size="small"
            name="password"
            label="비밀번호"
            autoComplete="on"
            variant="outlined"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password}
            placeholder="*********"
            type={passwordVisibility ? 'text' : 'password'}
            error={!!touched.password && !!errors.password}
            helperText={(touched.password && errors.password) as string}
            InputProps={{
              endAdornment: <EyeToggleButton show={passwordVisibility} click={togglePasswordVisibility} />
            }}
          />

          {/*로그인 버튼*/}
          <LoadingButton
            fullWidth
            loading={isSubmitting}
            type="submit"
            color="primary"
            variant="contained"
            sx={{ height: 44 }}
          >
            로그인
          </LoadingButton>
        </form>

        <FlexRowCenter my="1.25rem">
          <Box>
            <Typography letterSpacing="-0.3px">아직 회원이 아니신가요?</Typography>
          </Box>
          <a onClick={moveToSignUpPage}>
            <H6
              ml={1}
              borderColor="grey.900"
              letterSpacing="-0.3px"
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              회원가입
            </H6>
          </a>
        </FlexRowCenter>
        <Divider />

        <FlexRowCenter mt="1.25rem">
          <Stack
            direction="row"
            spacing={1}
            divider={
              <Box display="flex" alignItems="center" pb="1px">
                <Box sx={{ width: '1px', height: '9px', bgcolor: '#140229', opacity: 0.1 }} />
              </Box>
            }
          >
            <Link href="/svc/account/find/id">아이디 찾기</Link>
            <Link href="/svc/account/find/password">비밀번호 재설정</Link>
          </Stack>
        </FlexRowCenter>
      </Wrapper>
    </>
  );
};

const formSchema = yup.object().shape({
  email: yup.string().email('올바른 이메일 주소를 입력해주세요').required('이메일을 입력해주세요'),
  password: yup.string().required('비밀번호를 입력해주세요')
});

export default SignIn;
