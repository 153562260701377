import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export interface DeviceState {
  /** 초기화 완료 */
  initialized: boolean;
  /** userAgent os 정보 */
  os?: {
    name: string;
    version: string;
  };
  /** 제조업체 */
  vendor?: string;
  /** 모델 */
  model?: string;
  /** 기기 타입 */
  type?: string;

  /** 브라우저 정보 */
  browser?: {
    name: string;
    version: string;
    major: string;
  };

  /** 데스크탑 여부 */
  isDesktop: boolean;
  /** 태블릿 여부 */
  isTablet: boolean;
  /** 모바일 여부 */
  isMobile: boolean;

  /** iOS 여부 */
  isIOS: boolean;
  /** 안드로이드 여부 */
  isAndroid: boolean;
}

export const initialState: DeviceState = {
  initialized: false,
  isDesktop: false,
  isTablet: false,
  isMobile: false,
  isIOS: false,
  isAndroid: false
};

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    /** 사용자 firebase authentication 처리 */
    setDevice(state, action) {
      state.initialized = action.payload.initialized;
      state.os = action.payload.os;
      state.browser = action.payload.browser;
      state.vendor = action.payload.device.vendor;
      state.model = action.payload.device.model;

      state.type = action.payload.deviceType;

      state.isIOS = action.payload.isIOS;
      state.isAndroid = action.payload.isAndroid;

      state.isDesktop = action.payload.isDesktop;
      state.isTablet = action.payload.isTablet;
      state.isMobile = action.payload.isMobile;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: any) => {
      // console.log('### HYDRATE DEVICE ###', action.payload.device);
      return { ...state, ...action.payload.device };
    });
  }
});

export const { setDevice } = deviceSlice.actions;
export default deviceSlice;
