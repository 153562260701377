import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export interface ModalsState {
  isSignInModalOpen: boolean;
}

export const initialState: ModalsState = {
  isSignInModalOpen: false
};

const deviceSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    /** 전역 로그인 모달 열기 */
    openSignInModal(state): void {
      state.isSignInModalOpen = true;
    },
    /** 전역 로그인 모달 닫기 */
    closeSignInModal(state): void {
      state.isSignInModalOpen = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: any) => {
      // console.log('### HYDRATE MODALS ###', action.payload.modals);
      return { ...state, ...action.payload.modals };
    });
  }
});

export const { openSignInModal, closeSignInModal } = deviceSlice.actions;
export default deviceSlice;
